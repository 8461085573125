import {get, getExel, post, put,del} from "@/service/base";

/**
 * 获取页面分类
 * @returns {Promise<unknown>}
 */
export function pageType(){
    return get('/api/pageType')
}

/**
 * 获取内容分类
 * @returns {Promise<unknown>}
 */
export function contentType(){
    return get('/api/contentType')
}

/**
 * 获取分类查询出的条数
 * @returns {Promise<unknown>}
 */
export function pageTypeCount(data){
    return get('/api/pageTypeCount',data)
}

export function contentTypeCount(data){
    return get('/api/contentTypeCount',data)
}

/**
 * 页面数据导出
 * @param data
 * @returns {Promise<unknown>}
 */
export function reportPageExport(data){
    return getExel('/api/reportPageExport',data)
}

export function reportContentExport(data){
    return getExel('/api/reportContentExport',data)
}

//---------------------------------------------------------示例图-----------------------
/**
 * 新增示例图
 * @param data
 * @returns {Promise<* | void>}
 */
export function postExamplesImg(data){
    return post('/api/examplesImg',data)
}

/**
 * 修改示例图
 * @param data
 * @returns {Promise<unknown>}
 */
export function putExamplesImg(data){
    return put('/api/examplesImg',data)
}

/**
 * 删除
 * @param data
 * @returns {Promise<unknown>}
 */
export function delExamplesImg(id){
    return del('/api/examplesImg?id='+id)
}

export function getExamplesImg(data){
    return get('/api/examplesImg',data)
}


