<template>
  <div style="z-index: 9999999!important;">
    <a-modal  width="60%" v-model="propList.visible" title="示例图" @cancel="handleCancel" :footer="null">

<!--      <input multiple type="file" accept=".png,.jpeg,.ipg" ref="upFile" @change="bulkImport" style="display:none">-->
     <div style="display: flex">
<!--       <div style="margin-right: 10px">详情封面图:</div>-->
       <div class="uploadImg">
         <input
             style="cursor:pointer;width: 100px;height: 100px;opacity: 0;"
             accept="image/*"
             type="file"
             @change="bulkImport"
             id="bulkImportId"
             ref="bulkImportRef" />
       </div>
     </div>

<!--      <div style="min-height: 120px;display: flex">-->
<!--        <div>详情封面图：</div><div @click="toImport" class="uploadImg"></div>-->
<!--      </div>-->
      <div style="color: #a1a1a1;margin-bottom: 10px">注：大小不超过1M</div>
<!--      <a-row :gutter="16">-->
<!--        <a-col class="gutter-row" :span="6" v-for="(item,index) in imageList" :key="index">-->
<!--          <div class="gutter-box">-->
<!--            <div v-if="item" style="position: relative;">-->
<!--              <div class="bgcSty">-->
<!--                <a-icon @click="openImage(item)" type="eye" class="icon_eye"/>-->
<!--                <a-icon @click="delBtn(index)" type="close-circle" class="icon"/>-->
<!--              </div>-->
<!--              <img style="width: 100%;height: auto;" :src="item" alt="">-->
<!--            </div>-->
<!--          </div>-->
<!--        </a-col>-->
<!--      </a-row>-->
       <div  class="crosswiseDiv">
         <div v-for="(item,index) in imageList" :key="index" >
           <div v-if="item" style="position: relative;width: 200px;height: auto;margin: 5px">
             <div class="bgcSty">
               <a-icon @click="openImage(item)" type="eye" class="icon_eye"/>
               <a-icon @click="delBtn(index)" type="close-circle" class="icon"/>
             </div>
             <img style="width: 100%;height: auto;" :src="item" alt="">
           </div>
         </div>
       </div>
    </a-modal>
    <a-modal  width="40%" v-model="ImgVisible" @cancel="ImgCancel" :footer="null">
      <img style="width: 100%;height: auto;margin-top: 20px" :src="imgValue" alt="">
    </a-modal>
  </div>
</template>

<script>
import {update} from '../utils/update'
import {delExamplesImg, getExamplesImg, postExamplesImg, putExamplesImg} from "@/service/appDataExport";
export default {
  name: "PublicSample",
  props:{
    propList:Object
  },
  computed:{
  },
  created() {
  },
  watch:{
    propList(newVal,oldVal){
        if(newVal.code){
          this.getList(newVal.code)
        }
    }
  },
  data(){
    return{
      imgValue:null,
      ImgVisible:false,
      imageList:[],
      imageMessage:{},
    }
  },
  methods:{
    //获取示例图
    async getList(code) {
      let data = {
        code:code
      }
      const response = await getExamplesImg(data)
      if (response.code === 0) {
        if(response.data){
          if(response.data.imgUrl){
            this.imageList = response.data.imgUrl
            this.imageMessage = response.data
          }else {
            this.imageList = response.data
          }
        }
        console.log('获取时',this.imageList)
      } else {
        this.$message.warning(response.message)
      }
    },
    handleCancel() {
      this.propList.visible = false;
      this.imageList = []
      this.imageMessage = {}
    },
    // toImport(){
    //   //获取隐藏的input
    //   const inputFile = document.querySelector("input[type='file']");
    //   //触发其点击事件, 弹出文件选择
    //   inputFile.click();
    // },
    //获取文件流
    bulkImport() {
      let inputDOM = this.$refs.bulkImportRef.files[0];
      if (inputDOM.size < 1048576) {
        this.question(inputDOM)
      }else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async question(inputDOM) {
      const response = await update(inputDOM)
      if(response.code === 0){
        this.imageList.push(response.data.url)
        document.getElementById('bulkImportId').value = null
          //  修改
          let data = {
            "code":this.propList.code,
            "imgUrl":this.imageList
          }
        this.putExamplesImg(data)
      }else {
        this.$message.warning(response.message)
      }
    },
    // //新增示例图
    // async postExamplesImg(data) {
    //   const response = await postExamplesImg(data)
    //   if(response.code === 200){
    //     this.$message.success("新增成功~")
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    //修改示例图
    async putExamplesImg(data) {
      const response = await putExamplesImg(data)
      if(response.code === 200){
        this.$message.success("操作成功~")
      }else {
        this.$message.warning(response.message)
      }
    },
    // async delExamplesImg(id) {
    //   const response = await delExamplesImg(id)
    //   if(response.code === 200){
    //     this.$message.success("新增成功~")
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    //删除
    delBtn(index){
      let newList = [...this.imageList]
      newList.splice(index,1)
      this.imageList = newList
      //  修改
      let data = {
        // "id":this.imageMessage.id,
        "code":this.imageMessage.code,
        "imgUrl":this.imageList
      }
      this.putExamplesImg(data)
    },
    openImage(img){
      this.ImgVisible = true
      this.imgValue = img
    },
    ImgCancel(){
      this.ImgVisible = false
      this.imgValue = null
    },

  }
}
</script>

<style scoped>
.crosswiseDiv{
  width: auto;
  overflow-x: scroll;
  display: flex;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  padding: 5px 0;
  margin-bottom: 10px;
  height: auto;
}
.bgcSty{
  width: 100%;
  height: 20px;
  background: linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.3),rgba(0,0,0,0));
  position: relative;
  top: 20px;
  text-align: right;
  padding-right: 5px;
}
.icon{
  color: #f57474;
  cursor: pointer;
  z-index: 99999;
  font-size: 14px;
}
.icon:hover{
  color: red;
}
.icon_eye{
  color: #f57474;
  cursor: pointer;
  z-index: 99999;
  margin-right: 8px;
  /*font-size: 16px;*/
}
.icon_eye:hover{
  color: red;
}
</style>
